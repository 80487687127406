<template>
  <div class="theme-product-box">
    <section class="container-fluid theme-head">
      <h1 id="main-headline" class="main-headline" style="text-align: left">
        工厂自动化
      </h1>
      <div
        class="jsx-anchor-link"
        style="text-align: left; padding-top: 16px; padding-bottom: 32px"
      >
        <a class="jump-mark jump-mark-category-page">进一步了解该产品类别</a>
      </div>
      <div>
        <p class="h3 headline--ostqX">Engineering tools</p>
      </div>
      <div
        v-for="item in list"
        :key="item.id"
        style="display: flex; flex-direction: column"
      >
        <div class="engineering-tools-container">
          <div class="relative-recommand-operation">
            <img
              :src="item.img"
              alt=""
              style="
                width: 24px;
                height: 24px;
                margin-right: 12px;
                align-self: self-start;
              "
            />
            <div
              style="
                align-items: flex-start;
                cursor: pointer;
                display: flex;
                width: 90%;
                flex-direction: column;
              "
            >
              <p style="font-weight: 600">{{ item.title }}</p>
              <p style="font-size: 14px; margin-top: 6px">{{ item.intro }}</p>
            </div>
            <el-tooltip
              :visible="visible"
              style="
                align-self: flex-start;
                display: flex;
                height: 60px;
                justify-content: flex-end;
                margin-top: 2px;
                width: 10%;
              "
            >
              <template #content>
                <span>{{ item.title }}</span>
                <!-- <ul>
                <li v-for="(item2, index2) in item.pointList" :key="index2">
                  {{ item2 }}
                </li>
              </ul> -->
              </template>
              <div style="align-self: self-start">
                <el-icon
                  @mouseenter="visible = true"
                  @mouseleave="visible = false"
                  style="width: 14px; height: 14px"
                  ><Warning
                /></el-icon>
              </div>
            </el-tooltip>
          </div>
        </div>
      </div>
    </section>
    <section class="container-fluid product-category-box">
      <div
        style="padding: 24px 0; display: flex; justify-content: space-between"
      >
        <div class="filter-container">
          <ProductCatelogue></ProductCatelogue>
        </div>
        <div style="max-width: 75%; min-width: 75%; margin: 0 auto">
          <ProductIndustrial />
        </div>
      </div>
    </section>
    <section class="container-fluid theme-article-box">
      <el-row :gutter="10">
        <!-- <el-col :xs="8" :sm="6" :md="4" :lg="3" :xl="1"
          ><div>111111</div></el-col
        >
        <el-col :xs="4" :sm="6" :md="8" :lg="9" :xl="11"><div>222</div></el-col>
        <el-col :xs="4" :sm="6" :md="8" :lg="9" :xl="11"><div>333</div></el-col>
        <el-col :xs="8" :sm="6" :md="4" :lg="3" :xl="1"><div>44</div></el-col> -->
        <el-col :xs="24" :md="16">
          <div
            v-for="item in testData"
            :key="item.id"
            class="theme-article-list"
          >
            <div class="theme-article-item">
              <h2 class="theme-article-title">{{ item.title }}</h2>
              <p class="copytext content-copy__copytext">
                <strong class="content-copy__strong">{{ item.intro }}</strong>
              </p>
            </div>
          </div>
        </el-col>
        <!-- 带链接的 跳转对应类型产品列表页面 -->
        <el-col :xs="24" :md="16">
          <div
            v-for="item in testData2"
            :key="item.id"
            class="theme-category-product-list"
          >
            <div class="theme-category-product-item">
              <h3>{{ item.title }}</h3>
              <p
                class="copytext content-copy__copytext theme-category-product-intro"
              >
                {{ item.intro }}
              </p>
              <el-button
                color="#37137f"
                link
                class="theme-category-product-btn"
                >{{ item.title }}</el-button
              >
            </div>
          </div>
          <h2 class="accordion-vertical__headline">
            常见问题解答——常见问题释疑
          </h2>
          <el-collapse v-model="activeName" accordion>
            <el-collapse-item
              :title="item.question"
              :name="item.question"
              v-for="item in questionList"
              :key="item.id"
            >
              <div class="theme-question-title">{{ item.answer }}</div>
            </el-collapse-item>
            <!-- <el-collapse-item title="Feedback" name="2">
              <div>
                Operation feedback: enable the users to clearly perceive their
                operations by style updates and interactive effects;
              </div>
              <div>
                Visual feedback: reflect current state by updating or
                rearranging elements of the page.
              </div>
            </el-collapse-item>
            <el-collapse-item title="Efficiency" name="3">
              <div>
                Simplify the process: keep operating process simple and
                intuitive;
              </div>
              <div>
                Definite and clear: enunciate your intentions clearly so that
                the users can quickly understand and make decisions;
              </div>
              <div>
                Easy to identify: the interface should be straightforward, which
                helps the users to identify and frees them from memorizing and
                recalling.
              </div>
            </el-collapse-item>
            <el-collapse-item title="Controllability" name="4">
              <div>
                Decision making: giving advices about operations is acceptable,
                but do not make decisions for the users;
              </div>
              <div>
                Controlled consequences: users should be granted the freedom to
                operate, including canceling, aborting or terminating current
                operation.
              </div>
            </el-collapse-item> -->
          </el-collapse>
        </el-col>
      </el-row>
    </section>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import ProductIndustrial from "../../components/Product/ProductIndustrial.vue";
import ProductCatelogue from "../../components/Product/ProductCatelogue.vue";
const visible = ref(false);
let activeName = ref("");
let questionList = ref([
  {
    id: 1,
    question: "气缸的工作原理是什么？",
    answer:
      "Festo 的气伺服技术代表受控气动定位技术。一般而言，在气伺服系统中，气缸可以以位置受控的方式移动到预设的目标位置，或者以力受控的方式产生预设的目标力。气伺服技术是一种“软”定位技术，因为空气是一种可压缩的驱动介质。当驱动器处于受控位置时，它可以被相应大的外力推开。",
  },
  {
    id: 2,
    question: "气伺服定位技术的工作原理是什么？",
    answer:
      "Festo的气伺服技术代表受控气动定位技术。一般而言，在气伺服系统中，气缸可以以位置受控的方式移动到预设的目标位置，或者以力受控的方式产生预设的目标力。气伺服技术是一种“软”定位技术，因为空气是一种可压缩的驱动介质。当驱动器处于受控位置时，它可以被相应大的外力推开。",
  },
  {
    id: 3,
    question: "电驱产品的工作原理是什么？",
    answer:
      "电驱产品或机电驱动技术包括直线或旋转电缸。电机（伺服电机或步进电机）驱动齿形带或丝杠，进而使滑块沿直线方向移动。在旋转电缸和模块情况下，电机直接驱动内部旋转机械装置。伺服电机或步进电机通过相应的伺服驱动器进行驱动。",
  },
]);
let testData = ref([
  {
    id: 1,
    title: "驱动器和执行器",
    intro:
      "Ctrl Motor为工厂自动化领域的广泛应用提供驱动器。除了特别容易调试且成本效益高的气动驱动器外，我们还提供用于受控气动定位技术的气伺服定位系统和用于灵活直线或旋转定位的电驱产品。我们的驱动器作为气动和电气控制和驱动技术的核心元件，在众多行业领域得到应用。",
  },
  {
    id: 2,
    title: "驱动器和执行器用途",
    intro:
      "气动驱动器的常规应用领域包括机械运动，如夹紧、提升、埋头钻孔、抓取、阻挡等。气伺服驱动器主要用于“软”定位技术，因为空气是一种可压缩的驱动介质。电驱技术的典型应用领域遍及自动化技术，尤其是定位领域。",
  },
]);

let testData2 = ref([
  {
    id: 1,
    path: "",
    title: "气缸",
    intro:
      "Ctrl Motor的气缸范围包括活塞杆和无杆气缸（直线驱动器）、摆动气缸、倍力气缸、多位置气缸和阻挡气缸，以及夹紧气缸、带直线导轨的气缸以及气囊式和膜片式气缸。另外，我们还提供许多标准气缸，具有标准化的尺寸和安装接口。符合特定标准的气缸具有相同的尺寸。圆形结构 (ISO 6432)、紧凑型结构 (ISO 21287) 的活塞杆气缸以及型材气缸和拉杆式气缸 (ISO 15552) 都设有标准。",
  },
  {
    id: 2,
    path: "",
    title: "气伺服定位系统",
    intro:
      "作为全球气伺服系统的市场领导者，Ctrl Motor 目前推出了第三代气伺服驱动系统。我们的系统一般由带有位移编码器的气缸、比例方向控制阀和位置控制器/伺服定位控制器组成，我们还提供单独的位移编码器和传感器接口以及电缆和附件。这些系统物有所值，特别是用于移动重量超过 10 kg 的物体。",
  },
  {
    id: 2,
    path: "",
    title: "电驱产品",
    intro:
      "在我们的电驱产品系列中，除了直线电缸、有杆电缸和滑台式电缸之外，我们还提供电动旋转驱动器、止动器、抓取模块和电驱产品附件。驱动方式，如丝杠式驱动或齿形带驱动，与导轨一样起着重要作用。我们通常将导轨分为三类：滑动轴承导轨、循环滚珠轴承导轨和滚轮轴承导轨。电缸的特点首先是坚固性和刚性，以及精度和动态响应。例如，直线电缸的速度可达 10 m/s，加速度可达 50 m/s²，重复精度为 +/– 15 µm。标准行程可达 8.5 m。",
  },
]);

let list = ref([
  {
    id: 1,
    img: "https://www.Ctrl Motor.com/media/pim/912/D15000100159912.svg",
    title: "电驱运动选型",
    intro: "找到合适的电气或机电伺服驱动控制器。",
    pointList: [
      "快速、简便、可靠的计算",
      "免费访问 Ctrl Motor 网站-无需注册，即可下载和安装软件",
      "注册用户可以进行详细的仿真",
    ],
  },
  {
    id: 2,
    img: "https://www.Ctrl Motor.com/media/pim/834/D15000100159834.svg",
    title: "FluidDraw",
    intro: "使用 FluidDraw 更轻松地设计完整的系统。",
    pointList: [
      "IMX和 EPLAN接口",
      "管道长度、电线和电缆长度",
      "符合ISO 1219流体动力系统的液压符号",
    ],
  },
]);
</script>

<style scoped lang="scss">
.theme-product-box {
  .theme-head {
    margin-bottom: 35px;
    background-color: #fff;
    .engineering-tools-container {
      text-align: left;
      min-width: calc(25% - 24px);
      max-width: calc(25% - 24px);
    }
  }
  .jump-mark {
    margin: 0;
    font-weight: normal;
    font-size: 16px;
    color: #0091dc;
    cursor: pointer;
  }
  .headline--ostqX {
    align-items: center;
    display: flex;
    font-size: 24px;
    height: 32px;
    font-weight: normal;
    line-height: 32px;
    margin: 0 0 24px;
  }

  .relative-recommand-operation {
    align-items: center;
    background: #f2f3f5;
    display: flex;
    justify-content: flex-start;
    max-width: 100%;
    min-height: 61px;
    padding: 12px 12px 16px 16px;
    margin-bottom: 2px;
  }
}
.product-category-box {
  background-color: #f2f3f5;
  margin: 0 auto;
  max-width: 1440px;
}
.filter-container {
  display: block;
  margin-right: 24px;
  transition: transform 200ms ease-out;
}
@media (min-width: 768px) {
  .filters-container {
    max-width: calc(33.33% - 16px);
    min-width: calc(33.33% - 16px);
  }
}

@media (min-width: 1440px) {
  .filters-container {
    max-width: calc(25% - 18px);
    min-width: calc(25% - 18px);
  }
}
.theme-article-box {
  background-color: #fff;
  text-align: left;
  padding-top: 64px;
  padding-bottom: 16px;
  margin-bottom: 100px;

  .theme-article-list {
    display: flex;
    flex-direction: column;
    .theme-article-item {
      margin-bottom: 60px;
    }
  }

  .theme-article-title {
    margin-bottom: 16px;
  }

  .theme-category-product-list {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    .theme-category-product-item {
      margin-bottom: 60px;

      .theme-category-product-intro {
        margin-top: -4px;
        margin-bottom: 20px;
      }
    }
  }

  .theme-question-title {
    // font-weight: bold;
    font-size: 16px;
    padding: 0 30px 1px !important;
  }
}
</style>
<style>
.el-collapse-item__header {
  font-size: 16px !important;
  font-weight: bold;
  padding: 0 30px 1px !important;
}

.el-collapse-item .is-active,
.el-collapse-item__content {
  background-color: #f2f3f5;
}

.is-active {
  /* border-top: 1px solid #c4c4c4; */
}

.el-collapse-item__content {
  /* border-bottom: 1px solid #c4c4c4; */
}
</style>
