<template>
  <div class="footer-box">
    <div class="social-media-link">
      <svg width="48" height="48" viewBox="0 0 308 500" fill="#333">
        <path
          d="M222.22 111.11h83.33V0h-93.42c-73.24 0-128.8 55.56-128.8 111.11v55.56H0v111.11h83.33V500h111.11V277.78h83.33V166.67h-83.33v-27.78c0-17.31 18.28-27.78 27.78-27.78"
          fill="inherit"
        ></path>
      </svg>
      <svg width="48" height="48" viewBox="0 0 500 500" fill="#333">
        <g fill="inherit">
          <path
            d="M8.22 166.02h103.71V500H8.22zM60.11 0a60.2 60.2 0 1 0 60.05 60.19A60.11 60.11 0 0 0 60.11 0ZM375.71 157.73c-50.41 0-84.22 27.69-98 53.95h-1.42V166h-99.35v334h103.48V334.81c0-43.57 8.23-85.78 62.14-85.78 53.17 0 53.84 49.83 53.84 88.55V500H500V316.84c0-89.95-19.39-159.11-124.29-159.11Z"
          ></path>
        </g>
      </svg>
      <svg width="48" height="48" viewBox="0 0 736 592" fill="#333">
        <g transform="translate(-640 -112)">
          <path
            fill="inherit"
            d="M911.04 498.58c0-111.36 108.95-201.63 243.35-201.63 6.49 0 12.92.22 19.29.63C1153.55 192.41 1043.64 112 911.04 112c-146.88 0-265.94 98.66-265.94 220.35 0 68.35 37.56 129.44 96.51 169.85 11.14 7.64-18.2 69.05-5.64 75.12s67.02-43.2 80.78-38.87c29.31 9.21 61.09 14.25 94.3 14.25 2.96 0 5.9-.05 8.84-.13-5.76-17.18-8.85-35.29-8.85-53.99z"
          ></path>
          <path
            fill="inherit"
            d="M1374.64 499.67c0 56.61-31.11 107.2-79.93 140.68-9.23 6.33 15.07 57.19 4.67 62.22s-55.5-35.78-66.9-32.2c-24.27 7.63-50.6 11.8-78.1 11.8-121.65 0-220.26-81.71-220.26-182.5s98.61-182.5 220.26-182.5 220.26 81.71 220.26 182.5z"
          ></path>
          <circle fill="#fff" cx="1080.97" cy="440.51" r="31.44"></circle>
          <circle fill="#fff" cx="1227.8" cy="440.51" r="31.44"></circle>
        </g>
      </svg>
    </div>
    <div class="footer-nav">
      <div class="footer-nav-item" style="width: 350px; text-align: left">
        <p>在线商城</p>
        <div>
          注册帐号，立即下载CAD图档、查询价格，并收取电子报了解最新产品和趋势。
        </div>
        <el-button
          link
          type="primary"
          style="margin-top: 20px; font-size: 16px"
        >
          注册
        </el-button>
      </div>
      <div class="footer-nav-item">
        <p>快速链接</p>
        <div class="nav-item-link">核心产品系列</div>
        <div class="nav-item-link">Ctrl Motor产品系列</div>
        <div class="nav-item-link">工程设计软件</div>
      </div>

      <div class="footer-nav-item">
        <p>联系方式</p>
        <div class="nav-item-link">联系我们</div>
        <div class="nav-item-link">新闻门户</div>
        <div class="nav-item-link">授权经销商</div>
      </div>
      <div class="footer-nav-item">
        <p>行业解决方案</p>
        <div class="nav-item-link">汽车及一级供应商行业</div>
        <div class="nav-item-link">电子行业</div>
        <div class="nav-item-link">食品加工和包装行业</div>
        <div class="nav-item-link">生物技术、制药和化妆品行业化工行业</div>
        <div class="nav-item-link">水处理技术</div>
      </div>
    </div>
    <div style="margin-top: 70px; padding-bottom: 60px">
      Copyright ©中固维科(深圳)动力技术有限公司版权所有 备案号：<a
        href="https://beian.miit.gov.cn/"
        class="mitt"
        >粤ICP备2023082025号-3</a
      >
    </div>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
.footer-box {
  font-size: 16px;
  background: #f0f1f3;
  width: 100%;
  .social-media-link {
    height: 96px;
    width: 81%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    padding: 26px 36px 10px 36px;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #adb6bf;
    padding-top: 48px 0 60px;
    svg {
      margin-right: 50px;
    }
  }

  .footer-nav {
    // background: red;
    padding: 36px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 135px;
    .footer-nav-item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      p {
        font-weight: bold;
        margin: 0 0 16px;
      }
      .nav-item-link {
        margin-bottom: 16px;
      }
    }
  }
}
</style>
