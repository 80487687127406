<template>
  <div class="product-box">
    <div class="test">
      <div class="product-top">
        <h1>产品</h1>
        <p>
          为满足各种需求提供适用的解决方案：Ctrl
          Motor的产品样本和在线商店中提供有适用于工厂和过程自动化领域气动和电气控制及驱动技术的各种产品、系统和服务。
        </p>
      </div>
      <DigitalEngineering :list="typeSelection"></DigitalEngineering>
    </div>
    <ClientCase></ClientCase>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import DigitalEngineering from "../../components/Home/DigitalEngineering.vue";
import ClientCase from "../../components/Product/ClientCase.vue";

let typeSelection = ref([
  {
    img: "https://www.festo.com/media/cms/central/media/editorial/img/automation/EA_Overview_29765c_12_fix696x391.jpg",
    title: "电驱自动化",
    introduce:
      "无论是电动、气动还是两者的巧妙结合，Ctrl Motor 都提供了从运动控制到机电元件的无任何技术限制的跨技术解决方案。",
    textButton: "更多相关信息",
    alt: "Process Industry",
    path: "/products/123/biotechnology",
  },
  {
    img: "https://www.festo.com.cn/media/cms/media/editorial/img/automation/chemistry-industry_keyvisual_16913c_6_fix444x250.jpg",
    title: "培训和咨询一来自于工业，服务于工业",
    introduce:
      "我们的解决方案将教学方面的专业知识与制造技术专长相结合。我们培养员工的技能，实现最佳工作绩效，使您能够最大程度地提高生产力。",
    textButton: "更多相关信息",
    alt: "Seamless connectivity",
    path: "/product/123/biotechnology",
  },
]);
</script>

<style scoped lang="scss">
.product-box {
  .test {
    margin: 0 29px;
    padding: 0 60px;
  }
  .product-top {
    text-align: left;
    h1 {
      font-size: 32px;
      margin: 16px 0;
    }
    p {
      font-size: 24px;
      color: #8d8d8d;
      margin-bottom: 30px;
    }
  }
}
</style>
