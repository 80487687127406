<template>
  <div class="journal-item-box">
    <div class="journal-list">
      <div
        class="journal-item"
        v-for="item in props.journalList"
        :key="item.id"
      >
        <div>
          <el-image
            :src="item.img"
            alt=""
            fit="cover"
            style="width: 100%; height: 256px"
          />
          <div class="journal-item-content">
            <p class="journal-category">{{ item.categoryName }}</p>
            <p class="journal-title">{{ item.title }}</p>
            <p class="journal-content">{{ item.content }}</p>
            <el-button link type="primary" class="start"> Read more </el-button>
            <div class="share-journal-topic">
              <el-button link type="primary" class="">
                {{ item.topic }}
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 超出9个加分页显示 -->
  </div>
</template>

<script setup lang="ts">
import { defineProps } from "vue";
const props = defineProps({
  journalList: Array,
});
</script>

<style scoped lang="scss">
.journal-item-box {
  display: flex;
  flex-wrap: wrap; /* 允许子项换行 */
  height: auto;
  background-color: #f2f3f5;
  margin: 0 29px;
  padding: 0 50px;
  .journal-list {
    display: flex;
    flex-wrap: wrap; /* 允许子项换行 */
    justify-content: flex-start; /* 子项在主轴方向上的对齐方式 */
    align-items: stretch; /* 子项在交叉轴方向上的对齐方式，这里设置为stretch使得子项高度一致 */
    gap: 1.3vw;
  }
  .journal-item {
    // flex: 1 1 395px;
    // max-width: 395px;
    flex: 1 1 calc(33.333% - (0.5vw * 2)); /* 假设每行三个项目，减去gap产生的空间 */
    max-width: calc(33.333% - (0.5vw * 2)); /* 调整max-width以匹配flex-basis */
    display: flex;
    flex-direction: column;
    flex-wrap: no-wrap;
    background-color: #fff;
    // width: 32.5%;
    text-align: left;
    // margin-right: 16px;
    margin-bottom: 24px;
    .journal-item-content {
      padding: 18px 30px 0;
    }
    .journal-category {
      font-size: 14px;
      margin-bottom: 8px;
    }
    .journal-title {
      height: 80px;
      max-height: 80px;
      margin-bottom: 16px;
      font-size: 32px;
      font-weight: 500;
      line-height: 40px;
    }
    .journal-content {
      height: 48px;
      font-size: 16px;
      max-height: 48px;
      margin-bottom: 25px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2; /* 限制在两行 */
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .share-journal-topic {
      padding-top: 36px;
      padding-bottom: 36px;
    }
  }
}
// @media (max-width: 600px) {
//   .journal-item-box {
//     gap: 1vw; /* 在小屏幕上减小间隔 */
//   }

//   .journal-item {
//     flex-basis: 100%; /* 在小屏幕上让每个项目占满整行 */
//   }
// }
</style>
