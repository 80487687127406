<template>
  <img class="header-logo" :src="logoUrl" alt="ctrl-motor logo" />
</template>

<script setup>
import { ref } from "vue";
const logoUrl = ref(
  "https://ctrl-motor.oss-cn-hongkong.aliyuncs.com/static/global/ctrl-motor-cooperation.svg"
);
</script>

<style lang="scss" scoped>
.header-logo {
  width: 170px;
}
</style>
