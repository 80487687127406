<template>
  <div class="digital-engineering-box">
    <div
      v-for="item in props.list"
      :key="item.id"
      class="digital-engineering-item"
    >
      <el-image :src="item.img" alt="" fit="cover" />
      <h3>{{ item.title }}</h3>
      <p>{{ item.introduce }}</p>
      <el-button
        class="digital-engineering-button"
        link
        type="primary"
        @click="jump(item.path)"
        >{{ item.textButton }}</el-button
      >
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps } from "vue";
const props = defineProps({
  list: Array,
});
// const list = ref([
//   {
//     id: 1,
//     img: "https://www.festo.com/media/cms/media/editorial/img/person-blue-star-festo-core-product-range_fix696x391.jpg",
//     title: "核心产品系列",
//     introduce:
//       "品质卓越，价格极具吸引力，能充分满足气压自动化和电动自动化领域中几乎所有的常见运用。品质卓越，价格极具吸引力，能充分满足气压自动化和电动自动化领域中几乎所有的常见运用。品质卓越，价格极具吸引力，能充分满足气压自动化和电动自动化领域中几乎所有的常见运用。品质卓越，价格极具吸引力，能充分满足气压自动化和电动自动化领域中几乎所有的常见运用。",
//     textButton: "快速到货",
//     alt: "Process Industry",
//     path: "",
//   },
//   {
//     id: 2,
//     img: "https://www.festo.com/media/cms/media/editorial/img/precise-flexible-microdispenser_fix696x391.jpg",
//     title: "化工自动化",
//     introduce:
//       "全球Ctrl Motor客户的实际应用案例：从小零件组装的小型自动化应用、到北极圈的大型阀门驱动器。",
//     textButton: "各行各业",
//     alt: "Seamless connectivity",
//     path: "",
//   },
//   {
//     id: 3,
//     img: "https://www.festo.com/media/cms/media/editorial/img/cc/bionic-learning-network-00000-festo_fix696x391.jpg",
//     title: "仿生学习网络",
//     introduce:
//       "为工厂和过程自动化提供新动力，也是每年德国汉诺威工业展的万众瞩目焦点。",
//     textButton: "所有亮点",
//     alt: "Seamless connectivity",
//     path: "",
//   },
// ]);
</script>

<style lang="scss" scoped>
.digital-engineering-box {
  display: flex;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 50px;
  .digital-engineering-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 32%;
    margin-right: 25px;
    img {
      width: 100%;
      height: 250px;
    }
    h3 {
      text-align: left;
      margin-top: 15px;
      margin-bottom: 10px;
    }
    p {
      text-align: left;
      height: 48px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2; /* 限制在两行 */
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .digital-engineering-button {
      display: flex;
      justify-content: flex-end;
      font-size: 16px;
      margin-top: 16px;
    }
  }
  .digital-engineering-item:last-child {
    margin-right: 0;
  }
}
</style>
