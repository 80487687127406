<template>
  <div class="client-case-box">
    <p>客户应用实例</p>
    <JournalItem :journalList="list"></JournalItem>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import JournalItem from "../../components/Journal/JournalItem.vue";
const list = ref([
  {
    img: "https://www.festo.com/media/cms/central/media/editorial/img/automation/Syntegon-Application_Assembly_fix444x250.jpg",
    categoryName: "实践",
    title: "安全胰岛素笔的高速生产线",
    content:
      "如今，全球的糖尿病患病人数不断增加，每年新增的糖尿病患者约为 1000 万人。糖尿病患者可以使用",
    topic: "#生物技术/制药行业 #过程行业",
  },
  {
    img: "https://www.festo.com/media/cms/central/media/editorial/img/trends/journal_process-industries_barntrup_750_1212_fix444x250.jpg",
    categoryName: "实践",
    title: "高效利用血浆",
    content:
      "血浆是生产救命药物必不可少的资源。在全球市场需求旺盛。为了更有效地利用这种有价值的商品，Bio⋯。",
    topic: "#生物技术/制药行业 #过程行业",
  },
  {
    img: "https://www.festo.com/media/cms/central/media/editorial/img/trends/journal_process-industries_barntrup_750_1212_fix444x250.jpg",
    categoryName: "实践",
    title: "用于去除微污染物的纤维素球",
    content:
      "每年，仅在德国最大的河流莱茵河，就被排放了 8，000 吨的医药产品和10吨的塑料微粒。传统的污",
    topic: "#水处理#过程行业",
  },
  {
    img: "https://www.festo.com/media/cms/central/media/editorial/img/trends/journal_process-industries_barntrup_750_1212_fix444x250.jpg",
    categoryName: "实践",
    title: "用于去除微污染物的纤维素球",
    content:
      "每年，仅在德国最大的河流莱茵河，就被排放了 8，000 吨的医药产品和10吨的塑料微粒。传统的污",
    topic: "#水处理#过程行业",
  },
]);
</script>

<style scoped lang="scss">
.client-case-box {
  background: #f2f3f5;
  // margin: 30px 0 0;

  font-size: 24px;
  width: 100%;
  text-align: left;
  p {
    padding: 30px 0 0 80px;
    margin-bottom: 25px;
  }
}
</style>
