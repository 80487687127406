<template>
  <div class="menu-container" @click="closeSubMenu" ref="navBar">
    <ul class="menu">
      <li
        v-for="(item, index) in menuItems"
        :key="item.name"
        @click="toggleSubmenu(item, index)"
        :class="{ 'menu-item-selected': index === selectedMenuIndex }"
      >
        <!-- 导航栏的菜单 -->
        <span style="font-size: 16px">{{ item.name }}</span>
        <!-- 浮动显示的盒子内容 -->
        <div style="display: flex" class="show-sub-menu-box">
          <transition name="slide-fade">
            <div
              v-if="item.children && item.isSubmenuVisible"
              class="submenu-container full-width"
              style="display: flex"
            >
              <!-- 第二级菜单 -->
              <div class="sub-menu-border">
                <div>
                  <div
                    v-if="menuItems[selectedMenuIndex].children?.length != 0"
                    style="position: fixed; top: 35px"
                  >
                    <div>
                      <a class="menu-primary" href="/product"
                        ><img
                          src="https://ctrl-motor.oss-cn-hongkong.aliyuncs.com/static/global/right-arrow.svg"
                          alt=""
                          style="width: 24px; padding-right: 10px"
                        />{{ item.name }}
                      </a>
                    </div>
                  </div>
                  <!-- 点击li的时候选中当前的选择切换二级 -->
                  <div style="display: flex">
                    <ul class="submenu">
                      <li
                        v-for="(subItem, index2) in menuItems[selectedMenuIndex]
                          .children"
                        :key="subItem.name"
                        :class="{
                          'active-sub-menu-link': index2 === selectedFirstIndex,
                        }"
                        class="sub-menu-item"
                        @click="selectMenu(index2)"
                      >
                        <div :href="subItem.link" class="sub-menu-link">
                          <span class="menu-secondary">{{ subItem.name }}</span>
                          <span class="">{{ subItem.intro }}</span>
                        </div>
                        <img
                          src="https://ctrl-motor.oss-cn-hongkong.aliyuncs.com/static/global/right-arrow.svg"
                          alt=""
                          class="menu-item-arrow-right"
                        />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <!-- 第三级 -->
              <div class="sub-menu-border">
                <!--     background-color: yellow; -->
                <div style="height: 24px">
                  <a
                    href="#"
                    class="menu-primary"
                    style="position: fixed; /* 设置标题固定 */ top: 35px"
                    ><img
                      src="https://ctrl-motor.oss-cn-hongkong.aliyuncs.com/static/global/right-arrow.svg"
                      alt=""
                      style="width: 24px; height: 24px; padding-right: 10px"
                    />{{ currentSubDetail.name }}</a
                  >
                </div>
                <ul
                  class="thirdly-menu"
                  style="overflow-y: auto; overflow-x: hidden; height: 100%"
                >
                  <li
                    v-for="(subItem, subIndex) in currentSubDetail.children"
                    :key="subItem.name"
                    class="thirdly-menu-link"
                    :class="{
                      'active-sub-menu-link': subIndex === selectedThirdIndex,
                    }"
                    @click="selectThirdMenu(subIndex)"
                  >
                    <div
                      :href="subItem.link"
                      style="
                        display: flex;
                        justify-content: space-between;
                        width: 100%;
                      "
                    >
                      <div style="display: flex; align-items: center">
                        <img
                          src="https://www.festo.com/media/pim/513/D15000100123513.svg"
                          alt=""
                          style="width: 24px; height: 24px; margin-right: 10px"
                        />
                        {{ subItem.name }}
                      </div>
                      <img
                        v-if="subItem.children"
                        src="https://ctrl-motor.oss-cn-hongkong.aliyuncs.com/static/global/right-arrow.svg"
                        alt=""
                        class="menu-item-arrow-right"
                      />
                    </div>
                  </li>
                </ul>
              </div>
              <!-- 第四级 正式跳转 -->
              <div
                class="sub-menu-border"
                v-if="currentThirdlyItems.children.length != 0"
              >
                <a href="#" class="menu-primary"
                  ><img
                    src="https://ctrl-motor.oss-cn-hongkong.aliyuncs.com/static/global/right-arrow.svg"
                    alt=""
                    style="width: 24px; height: 24px; padding-right: 10px"
                  />{{ currentThirdlyItems.name }}</a
                >
                <ul class="thirdly-menu">
                  <li
                    v-for="subSubItem in currentThirdlyItems.children"
                    :key="subSubItem.name"
                    class="thirdly-menu-link"
                  >
                    <a :href="subSubItem.link">{{ subSubItem.name }}</a>
                  </li>
                </ul>
              </div>
            </div>
          </transition>
        </div>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { reactive, ref, computed, onMounted } from "vue";

interface MenuItem {
  id: number;
  name: string;
  link?: string;
  children?: MenuItem[];
  isSubmenuVisible?: boolean;
}

// 整个菜单
let selectedMenuIndex = ref(20);
let selectedFirstIndex = ref(0);
let selectedSecondIndex = ref(0);
let selectedThirdIndex = ref(0);
let selectedFourthlyIndex = ref(0);
// 选择一级菜单
const selectMenu = (index: number) => {
  selectedFirstIndex.value = index;
  selectedThirdIndex.value = 0;
};

const currentSubDetail = computed(() => {
  console.log(
    `output->currentSubDetail`,
    menuItems[selectedMenuIndex.value].children[selectedFirstIndex.value]
  );
  // 获取当前选中的项
  const { children, name } =
    menuItems[selectedMenuIndex.value].children[selectedFirstIndex.value];
  // 检查当前项是否定义了
  if (children && name) {
    // 返回包含 name 和 children 的对象
    return {
      name: name || "",
      children: children || [],
    };
  } else {
    // 如果当前项未定义，返回一个包含空 name 和空 children 数组的对象
    return [];
  }
});

const currentThirdlyItems = computed(() => {
  const thirdlyItem = currentSubDetail.value.children[selectedThirdIndex.value];
  if (thirdlyItem) {
    return {
      name: thirdlyItem.name,
      children: thirdlyItem.children || [],
    };
  } else {
    return { name: "", children: [] };
  }
});
// 通用

// 测试
const navBar = ref<HTMLElement | null>(null);

const closeSubMenu = (event: MouseEvent) => {
  if (navBar.value && !navBar.value.contains(event.target as Node)) {
    menuItems[selectedMenuIndex.value].isSubmenuVisible = false;
  }
};
const menuItems = reactive<MenuItem[]>([
  {
    id: 1,
    name: "产品",
    link: "/products",
    children: [
      {
        id: 11,
        name: "工业自动化",
        intro: "用於製造和組裝自動化的產品",
        link: "#",
        children: [
          {
            id: 13,
            name: "驱动器",
            link: "#",
            children: [
              { name: "气缸", link: "#" },
              { name: "气伺服定位系统", link: "#" },
              { name: "电缸", link: "#" },
            ],
          },
          {
            name: "电机和伺服驱动器",
            link: "#",
            children: [
              {
                name: "伺服电机",
                link: "#",
                children: [{ name: "test", link: "#" }],
              },
              { name: "步进电机", link: "#" },
              { name: "伺服驱动器", link: "#" },
              { name: "減速机", link: "#" },
              { name: "伺服驱动器附件", link: "#" },
            ],
          },
          {
            name: "抓手",
            link: "#",
            children: [
              { name: "平行抓手", link: "#" },
              { name: "三点抓手", link: "#" },
              { name: "摆动抓手", link: "#" },
              { name: "旋转抓手", link: "#" },
              { name: "摆动/抓手单元", link: "#" },
              { name: "气囊式抓手", link: "#" },
              { name: "抓手附件", link: "#" },
            ],
          },
          {
            name: "抓取系统",
            link: "#",
            children: [
              { name: "抓取模块", link: "#" },
              { name: "笛卡尔系统", link: "#" },
              { name: "平行运动系统", link: "#" },
              { name: "控制系统", link: "#" },
              { name: "抓取模块的附件", link: "#" },
            ],
          },
          {
            name: "真空技术",
            link: "#",
            children: [
              { name: "真空发生器", link: "#" },
              { name: "真空抓取技术", link: "#" },
              { name: "经典", link: "#" },
              { name: "真空附件", link: "#" },
            ],
          },
          {
            name: "阀和阀岛",
            link: "#",
            children: [
              { name: "电控和气控方向控制阀", link: "#" },
              { name: "机控和手控方向控制阀", link: "#" },
              { name: "截止阀", link: "#" },
              { name: "减压阀", link: "#" },
              { name: "节流阀", link: "#" },
              { name: "比例阀", link: "#" },
              { name: "电磁驱动过程和介质阀", link: "#" },
              { name: "气控和机械式过程和介质阀", link: "#" },
              { name: "压电阀", link: "#" },
              { name: "气动控制系统", link: "#" },
              { name: "经典", link: "#" },
              { name: "阀的附件", link: "#" },
              { name: "阀岛", link: "#" },
            ],
          },
          {
            name: "Motion Terminal（数字控制终端）",
            link: "#",
          },
          {
            name: "传感器",
            link: "#",
            children: [
              { name: "接近开关", link: "#" },
              { name: "电感式传感器", link: "#" },
              { name: "位置传感器", link: "#" },
              { name: "压力和真空传感器", link: "#" },
              { name: "流量传感器", link: "#" },
              { name: "光电式传感器", link: "#" },
              { name: "信号转换器", link: "#" },
              { name: "机电式开关", link: "#" },
              { name: "经典", link: "#" },
              { name: "气隙式传感器", link: "#" },
              { name: "附件", link: "#" },
            ],
          },
          {
            name: "压缩空气处理",
            link: "#",
            children: [
              { name: "气源处理装置", link: "#" },
              { name: "过滤減压阀/油雾器", link: "#" },
              { name: "过滤减压阀", link: "#" },
              { name: "过滤器", link: "#" },
              { name: "调压阀", link: "#" },
              { name: "油雾器", link: "#" },
              { name: "开关阀和软启动阀", link: "#" },
              { name: "空气干燥器", link: "#" },
              { name: "分气块", link: "#" },
              { name: "发生器", link: "#" },
              { name: "冷凝水排放装置", link: "#" },
              { name: "增压阀", link: "#" },
              { name: "经典", link: "#" },
              { name: "气源处理附件", link: "#" },
            ],
          },
          {
            name: "连接技术",
            link: "#",
            children: [
              { name: "电连接技术", link: "#" },
              { name: "气动连接技术", link: "#" },
              { name: "流体介质的连接技术", link: "#" },
            ],
          },
          {
            name: "控制技术和远程1/0",
            link: "#",
            children: [
              { name: "气动和电气控制器", link: "#" },
              { name: "远程1/0和网络接口组件", link: "#" },
              { name: "控制器与运动控制", link: "#" },
              { name: "人机界面", link: "#" },
              { name: "附件", link: "#" },
              { name: "培训系统", link: "#" },
              { name: "文档", link: "#" },
            ],
          },
          {
            name: "即装即用型解决方案",
            link: "#",
            children: [{ name: "带附件的气缸", link: "#" }],
          },
          {
            name: "特定功能系统",
            link: "#",
            children: [
              { name: "连接技术", link: "#" },
              { name: "抓取解决方案", link: "#" },
            ],
          },
          {
            name: "其它气动设备",
            link: "#",
            children: [
              { name: "储气罐", link: "#" },
              { name: "消声器", link: "#" },
              { name: "气枪", link: "#" },
              { name: "气动指示器", link: "#" },
              { name: "标签系统", link: "#" },
            ],
          },
          {
            name: "其它气动设备",
            link: "#",
            children: [
              { name: "调节服务", link: "#" },
              { name: "节能服务", link: "#" },
            ],
          },
        ],
      },
      {
        name: "过程自动化",
        intro: "用于液体与散料输送的产品",
        link: "#",
        children: [
          {
            name: "控制技术和远程I/O",
            link: "#",
            children: [
              { name: "远程I/O和电气外围设备", link: "#" },
              { name: "气动和电气控制器", link: "#" },
              { name: "人机界面", link: "#" },
              { name: "软件", link: "#" },
              { name: "文档", link: "#" },
            ],
          },
          {
            name: "閥島",
            link: "#",
            children: [
              { name: "标准阀岛", link: "#" },
              { name: "通用型阀岛", link: "#" },
              { name: "针对应用场合的阀岛", link: "#" },
              { name: "电气外围元件", link: "#" },
              { name: "阀岛附件", link: "#" },
            ],
          },
          {
            name: "先导阀",
            link: "#",
          },
          {
            name: "限位开关附件",
            link: "#",
          },
          {
            name: "定位器",
            link: "#",
          },
          {
            name: "驱动器",
            link: "#",
            children: [
              { name: "直线驱动器", link: "#" },
              { name: "摆动气缸", link: "#" },
            ],
          },
          {
            name: "过程阀",
            link: "#",
            children: [
              { name: "球阀和球阀单元", link: "#" },
              { name: "角座阀", link: "#" },
              { name: "夹管阀", link: "#" },
              { name: "电磁介质阀", link: "#" },
              { name: "成套过程阀", link: "#" },
              { name: "气控介质阀", link: "#" },
            ],
          },
          {
            name: "压缩空气处理",
            link: "#",
            children: [
              { name: "气源处理装置", link: "#" },
              { name: "过滤减压阀/油雾器", link: "#" },
              { name: "过滤减压阀", link: "#" },
              { name: "压缩空气过滤器", link: "#" },
              { name: "减压阀", link: "#" },
              { name: "油雾器", link: "#" },
              { name: "开关阀和软启动阀", link: "#" },
              { name: "空气干燥机", link: "#" },
              { name: "分气块", link: "#" },
              { name: "发生器", link: "#" },
              { name: "冷凝水排放装置", link: "#" },
              { name: "气源处理附件", link: "#" },
              { name: "增压阀", link: "#" },
              { name: "压力指示器", link: "#" },
            ],
          },
          {
            name: "气动连接技术",
            link: "#",
            children: [
              { name: "气管", link: "#" },
              { name: "接头", link: "#" },
              { name: "硬管", link: "#" },
              { name: "快插接头，用于硬管 PQ", link: "#" },
              { name: "连接插座/插头", link: "#" },
              { name: "分气块", link: "#" },
              { name: "防护套管系统", link: "#" },
              { name: "附件用于气动连接技术", link: "#" },
            ],
          },
          {
            name: "服务",
            link: "#",
            children: [{ name: "节能服务", link: "#" }],
          },
        ],
      },
      {
        name: "生命科技自动化",
        intro: "用于实验室设备和医疗设备的产品",
        link: "#",
        children: [
          {
            name: "控制技術和遠端1/0",
            link: "#",
            children: [{ name: "遠程1/0和電氣週邊設備", link: "#" }],
          },
          {
            name: "閥島",
            link: "#",
            children: [
              { name: "標準閥島", link: "#" },
              { name: "通用型閥島", link: "#" },
            ],
          },
        ],
      },
      {
        name: "数字化工程设计工具",
        intro: "用于正确设计产品和优化产品选型的软件",
        link: "#",
        children: [
          {
            name: "控制技術和遠端1/0",
            link: "#",
            children: [{ name: "遠程1/0和電氣週邊設備", link: "#" }],
          },
          {
            name: "閥島",
            link: "#",
            children: [
              { name: "標準閥島", link: "#" },
              { name: "通用型閥島", link: "#" },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 2,
    name: "解决方案",
    link: "/solutions",
    children: [
      {
        name: "解决方案 二级1",
        link: "#",
        children: [
          {
            name: "解决方案 三级1",
            link: "#",
            children: [{ name: "解决方案 四级1", link: "#" }],
          },
          { name: "解决方案 三级2", link: "#" },
        ],
      },
      { name: "解决方案 二级2", link: "#" },
    ],
  },
  {
    name: "支持",
    link: "/Support",
    children: [
      {
        name: "Sub Support 1",
        link: "#",
        children: [{ name: "Sub Support 1", link: "#" }],
      },
      { name: "Sub Support 2", link: "#" },
    ],
  },
  {
    name: "教学与培训",
    link: "/journal",
    children: [
      {
        name: "Sub Journal 1",
        link: "#",
        children: [{ name: "Sub Journal 1", link: "#" }],
      },
      { name: "Sub Journal 2", link: "#" },
    ],
  },
  {
    name: "应用案例",
    link: "/solutions",
    children: [
      {
        name: "Sub Product 1",
        link: "#",
        children: [{ name: "Sub Technical education 1", link: "#" }],
      },
      { name: "Sub Technical education 2", link: "#" },
    ],
  },
  {
    name: "关于 Ctrl Market",
    link: "/about",
    children: [
      {
        name: "Sub Product 1",
        link: "#",
        children: [{ name: "Sub About Ctrl Market 1", link: "#" }],
      },
      { name: "Sub About Ctrl Market 2", link: "#" },
    ],
  },
  {
    name: "人才招募",
    link: "/careers",
    children: [
      {
        name: "Sub careers 1",
        link: "#",
        children: [{ name: "Sub careers 1", link: "#" }],
      },
      { name: "Sub careers 2", link: "#" },
    ],
  },
]);

// 控制子菜单的浮窗
function toggleSubmenu(item: MenuItem, navBarIndex: number) {
  // selectedMenuIndex.value = navBarIndex;
  // console.log(`output->dd?`);
  // if (item.children) {
  //   selectThirdMenu(0);
  //   currentSubDetail.value.children =
  //     menuItems[selectedMenuIndex.value].children[
  //       selectedThirdIndex.value
  //     ].children;
  //   selectedThirdIndex.value = 0;
  //   // 如果子菜单已经显示，则不进行操作，否则显示子菜单
  //   if (!item.isSubmenuVisible) {
  //     item.isSubmenuVisible = true;
  //   }
  // }
  // 关闭之前打开的子菜单
  // 2-
  // menuItems.forEach((menuItem) => {
  //   if (menuItem !== item && menuItem.isSubmenuVisible) {
  //     menuItem.isSubmenuVisible = false;
  //   }
  // });

  // selectedMenuIndex.value = navBarIndex;

  // if (item.children) {
  //   selectThirdMenu(0);
  //   currentSubDetail.value.children =
  //     menuItems[selectedMenuIndex.value].children[
  //       selectedThirdIndex.value
  //     ].children;
  //   selectedThirdIndex.value = 0;
  //   if (!item.isSubmenuVisible) {
  //     item.isSubmenuVisible = true;
  //   }
  // }
  // 切换菜单的显示状态
  // 如果当前子菜单已经是显示状态，直接返回，不执行任何操作
  // 如果当前子菜单已经是显示状态，则关闭子菜单并返回
  if (item.isSubmenuVisible) {
    item.isSubmenuVisible = false;
    return;
  }

  // 关闭其他所有子菜单
  for (const menuItem of menuItems) {
    if (menuItem !== item) {
      menuItem.isSubmenuVisible = false;
    }
  }

  // 显示当前子菜单
  item.isSubmenuVisible = true;

  // 执行其他相关操作，比如更新选中菜单索引等
  selectedMenuIndex.value = navBarIndex;
  selectThirdMenu(0);
  currentSubDetail.value.children =
    menuItems[selectedMenuIndex.value].children[
      selectedThirdIndex.value
    ].children;
  selectedThirdIndex.value = 0;
}

const selectThirdMenu = (index: number) => {
  selectedThirdIndex.value = index;
};

onMounted(() => {
  document.addEventListener("click", closeSubMenu);
});
</script>

<style lang="scss" scoped>
ul {
  display: flex;
  align-items: center;
  height: 24px;
  // height: calc(100% - 24px);
  li {
    padding: 0 20px;
  }
}

a {
  display: flex;
  align-items: center;
}
.menu-container {
  width: 100%; /* 确保父容器宽度适应视口 */

  .menu-item-selected:hover,
  .menu-item-selected:visited,
  .menu-item-selected {
    border-bottom-color: #0091dc;
    z-index: 25;
  }

  .sub-menu-border {
    border-left: 1px solid #e6e8eb;
    padding: 10px 25px 75px 25px;
    width: 28%;
    height: 495px;
  }

  .menu-primary {
    margin-bottom: 30px;
    // position: fixed;
    // top: 0;
    // left: 0;
    // width: 100%;
    // z-index: 100;
    // padding-top: 10px; /* 添加顶部间距 */
  }
  .thirdly-menu {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 30px;
    .thirdly-menu-link {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      margin-bottom: 25px;
    }
  }

  .menu-primary,
  .menu-secondary {
    font-weight: bold;
    font-size: 16px;
  }

  .menu,
  .submenu {
    list-style-type: none;
    padding: 0;
    margin: 0;
    // width: 100%;
    .sub-sub-menu {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
  .sub-menu-item {
    height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .submenu {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 50px;
    .sub-menu-link {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
  .show-sub-menu-box ul > li {
    margin-bottom: 25px;
  }

  .menu-item-arrow-right {
    width: 24px;
    height: 24px;
    padding-right: 30px;
  }

  .menu > li {
    position: relative;
  }

  .active-sub-menu-link {
    color: #0091dc;
    border-left: 3px solid #0091dc;
    margin-left: -25px;
    padding-left: 40px;
  }

  .submenu-container {
    z-index: 9999;
    font-size: 14px;
    position: fixed;
    top: 60px;
    left: 0;
    width: 100%;
    // background-color: red;
    background-color: rgba(255, 255, 255, 0.8); /* 半透明白色背景 */
    backdrop-filter: blur(10px); /* 磨砂玻璃效果 */
    -webkit-backdrop-filter: blur(10px); /* 针对旧版Safari浏览器的兼容性 */
    border: 1px solid rgba(255, 255, 255, 0.18); /* 可选，添加边框以增强效果 */
    padding: 24px 66px 55px 66px;
    overflow: auto;
    min-height: 575px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  .menu > li .submenu-container {
    display: block;
  }
}

// .slide-fade-enter-active,
// .slide-fade-leave-active {
//   transition: opacity 0.5s ease, max-height 0.5s ease;
//   overflow: hidden;
// }

// .slide-fade-enter,
// .slide-fade-leave-to {
//   opacity: 0;
//   max-height: 0;
// }

// /* 设置一个足够大的 max-height 以容纳内容展开时的高度 */
// .slide-fade-enter-to,
// .slide-fade-leave {
//   max-height: 500px; /* 假设内容高度不超过 500px */
//   opacity: 1;
// }
@keyframes slideFade {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.slide-fade-enter-active {
  animation: slideFade 0.3s ease-out forwards;
}
</style>
