<template>
  <div class="banner-list-box">
    <el-carousel
      trigger="click"
      height="750px"
      arrow="never"
      @setActiveItem="changeBanner"
      ref="bannerCmp"
    >
      <el-carousel-item
        v-for="item in bannerList"
        :key="item"
        :name="item.title"
      >
        <div class="hero-section-img">
          <el-image :src="item.url" fit="cover" class="carousel-image" />
          <div class="overlay">
            <div class="clickable-boxes">
              <!-- 示例：可根据需求调整盒子数量和内容 -->
              <!-- <div
                class="clickable-box active-banner-module"
                @click="handleMouseEnter(index)"
              >
                <span
                  style="
                    border-bottom: 1px solid #faf9f8;
                    padding: 0 0 12px;
                    width: 300px;
                    font-size: 30px;
                    text-align: left;
                  "
                >
                  {{ item.title }}
                </span>
                <div
                  style="margin: 20px 0 36px; height: 115px; text-align: left"
                >
                  {{ item.intro }}
                </div>
                <el-button
                  style="
                    height: 43px;
                    color: #faf9f8;
                    background-color: #ffffff26;
                    font-size: 16px;
                  "
                  round
                >
                  {{ item.buttonText }}
                </el-button>
              </div> -->
              <!--    v-for="(item2, index2) in bannerList"
                :key="item2"
              :class="{
                  'active-banner-module': index === selectedBannerModuleIndex,
                }"-->
              <div class="clickable-box" @click="handleMouseEnter(index)">
                <span
                  style="
                    border-bottom: 1px solid #faf9f8;
                    padding: 0 0 12px;
                    width: 300px;
                    font-size: 14px;
                    font-size: 18px;
                    text-align: left;
                  "
                >
                  {{ item.title }}
                </span>
                <div
                  style="margin: 20px 0 36px; height: 115px; text-align: left"
                >
                  {{ item.intro }}
                </div>
                <el-button
                  style="
                    height: 43px;
                    color: #faf9f8;
                    background-color: #ffffff26;
                    font-size: 16px;
                  "
                  round
                >
                  {{ item.buttonText }}
                </el-button>
              </div>
            </div>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>
<script setup>
import { ref } from "vue";
// 组件的ref
let bannerCmp = ref(null);
// 选择当前轮播图下方的信息下标
let selectedBannerModuleIndex = ref(0);
// 是否自动播放，离开下方盒子信息中就恢复自动播放
let autoplay = ref(true);
// 鼠标是否停留在轮播图下方的信息中
let isHovering = ref(false);
// 标志来阻止后续的重复调用
let hasChangedBanner = ref(false);

// 轮播图数据
let bannerList = ref([
  {
    url: "https://zgwksz.oss-cn-shenzhen.aliyuncs.com/static/case/%E6%A1%88%E4%BE%8B%E5%9B%BE%E7%89%87%EF%BC%88%E6%94%B9%E8%BF%87%EF%BC%89-1213/600X450-%E6%A1%88%E4%BE%8B%E5%9B%BE%E7%89%87-%E8%88%AA%E7%A9%BA%E8%88%AA%E5%A4%A9_%28508mmX381mm%29_005.jpg",
    title: "简单、直接的在线购物体验",
    intro:
      "从查询产品价格、出货日期，到一键下单，甚至储存、分享您的购物车。全新的在线购物体验，让产品订购如此简单！",
    buttonText: "注册并登入",
  },
  {
    url: "https://zgwksz.oss-cn-shenzhen.aliyuncs.com/static/case/%E6%A1%88%E4%BE%8B%E5%9B%BE%E7%89%87%EF%BC%88%E6%94%B9%E8%BF%87%EF%BC%89-1213/600X450-%E6%A1%88%E4%BE%8B%E5%9B%BE%E7%89%87-%E8%88%AA%E7%A9%BA%E8%88%AA%E5%A4%A9_%28508mmX381mm%29_002.jpg",
    title: "Ctrl Motor官方帐号上线",
    intro:
      "欢迎扫描行动条码加入Ctrl Motor官方帐号，与我们一起探讨行业的热门焦点与最新趋势！",

    buttonText: "选型、采购更轻松",
  },
  {
    url: "https://zgwksz.oss-cn-shenzhen.aliyuncs.com/static/case/%E6%A1%88%E4%BE%8B%E5%9B%BE%E7%89%87%EF%BC%88%E6%94%B9%E8%BF%87%EF%BC%89-1213/600X450-%E6%A1%88%E4%BE%8B%E5%9B%BE%E7%89%87-%E7%94%9F%E7%89%A9%E5%8C%BB%E7%96%97_%28508mmX381mm%29_001.jpg",
    title: "快速出货",
    intro:
      "为了随时满足您的自动化需求，Ctrl Motor精选数款产品，提供充足的库存与最短的交货时间，让您享有最佳性价比的产品和服务！产品型号请先与您的 工程师确认！",
    buttonText: "轻松满足需求",
  },
  {
    url: "https://zgwksz.oss-cn-shenzhen.aliyuncs.com/static/case/%E6%A1%88%E4%BE%8B%E5%9B%BE%E7%89%87%EF%BC%88%E6%94%B9%E8%BF%87%EF%BC%89-1213/600X450-%E6%A1%88%E4%BE%8B%E5%9B%BE%E7%89%87-%E7%94%9F%E7%89%A9%E5%8C%BB%E7%96%97_%28508mmX381mm%29_002.jpg",
    title: "Ctrl Motor数字化生产",
    intro:
      "通往数字化生产的路很长，Ctrl Motor为您提供智能软件、硬件和工程设计工具为您提供积极支持。",

    buttonText: "前往了解",
  },
]);

// 鼠标在轮播图下方的信息中的处理
const handleMouseEnter = (index) => {
  autoplay.value = false;
  if (selectedBannerModuleIndex.value !== index) {
    selectedBannerModuleIndex.value = index;
    isHovering.value = true;
    changeBanner(selectedBannerModuleIndex.value);
    console.log(
      `output->selectedBannerModuleIndex.value`,
      selectedBannerModuleIndex.value
    );
  }
};

// 鼠标离开轮播图下方的信息
const handleMouseLeave = () => {
  isHovering.value = false;
  autoplay.value = true;
  // hasChangedBanner.value = false; // 重置标志允许再次调用changeBanner
};

// 指定banner下标跳转
const changeBanner = (index) => {
  console.log(`output->进来了`, index);
  if (isHovering.value == true && autoplay.value == false) {
    console.log(`output->跳转了`, index);
    bannerCmp.value?.setActiveItem(index);
  }
};
</script>

<style lang="scss" scoped>
.banner-list-box {
  .hero-section-img {
    position: relative; /* 设置相对定位，以便遮罩层可以正确定位 */
    width: 100%;
    height: 750px; /* 或根据需要调整高度 */

    .carousel-image {
      width: 100%;
      height: 100%;
    }

    .overlay {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.2); /* 半透明遮罩层 */
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 170px;
      .clickable-boxes {
        display: flex;
        justify-content: flex-end;
        width: 100%;

        .clickable-box {
          display: flex;
          justify-content: flex-start;
          flex-direction: column;
          align-items: flex-start;
          width: 300px;
          padding: 10px 20px;
          //
          // backdrop-filter: blur(30px);
          // background-color: rgba(0, 0, 0, 0.1); /* 半透明黑色背景 */
          letter-spacing: 1px;
          // border-radius: 5px;
          padding: 20px;
          color: #faf9f8; /* 白色文字 */
          cursor: pointer;
        }
        .active-banner-module {
          // opacity: 1;
        }
      }
    }
  }
}
</style>
