import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/home/HomeView.vue";
import ProductView from "../views/product/ProductView.vue";
import ProductCategory from "../views/product/ProductCategory.vue";
import ThemeProduct from "../views/product/ThemeProduct.vue";
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "root",
    component: HomeView,
  },
  {
    path: "/home",
    name: "home",
    alias: "/", // 将/home路径设置为别名，指向根路径
    component: HomeView,
  },
  {
    path: "/products",
    name: "products",
    component: ProductView,
  },
  {
    path: "/products/:id",
    name: "productCategory",
    component: ProductCategory,
  },
  {
    path: "/products/:id/:industryType",
    component: ThemeProduct,
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/product-details",
    name: "productDetails",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/product/ProductDetails.vue"
      ),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
