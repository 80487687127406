<template>
  <div class="feature-card-box">
    <div class="feature-card-left">
      <div v-for="item in props.industryList" :key="item.id">
        <div class="feature-card-item">
          <el-image
            :src="item.img"
            :alt="item.alt"
            fit="cover"
            class="feature-card-img"
          />
          <div class="feature-card">
            <p class="feature-card-title">{{ item.title }}</p>
            <p class="">{{ item.introduce }}</p>
            <el-button link class="feature-card-button" type="primary">{{
              item.textButton
            }}</el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="feature-card-right">
      <el-image
        :src="props.solution.img"
        class="feature-card-img"
        fit="cover"
      />
      <div class="feature-card">
        <p class="feature-card-title">{{ props.solution.title }}</p>
        <p>{{ props.solution.introduce }}</p>
        <el-button class="feature-card-button find-out-more" type="primary">{{
          props.solution.textButton
        }}</el-button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps } from "vue";
const props = defineProps({
  industryList: Array,
  solution: Object,
});

// let list = ref([
//   {
//     img: "https://www.festo.com.cn/media/cms/media/editorial/img/automation/Werkzeugmaschinen_Funktionsuebersicht_26571kc_1_fix444x250.jpg",
//     title: "机床自动化",
//     introduce: "实现机床的生产率最大化水平",
//     textButton: "了解详情",
//     alt: "Process Industry",
//   },
//   {
//     img: "https://www.festo.com.cn/media/cms/media/editorial/img/automation/chemistry-industry_keyvisual_16913c_6_fix444x250.jpg",
//     title: "化工自动化",
//     introduce: "智能自动化解决方案",
//     textButton: "了解更多",
//     alt: "Seamless connectivity",
//   },
// ]);
</script>

<style lang="scss" scoped>
.feature-card-box {
  padding: 0 60px;
  margin: 70px 25px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  .feature-card-left {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-right: 10px;
    .feature-card-item {
      margin-bottom: 25px;
      display: flex;
      width: 648px;
      height: 162px;
      align-items: center;
      justify-content: flex-start;
      .feature-card-img {
        width: 312px;
        height: 160px;
      }
      .feature-card {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 20px 24px;
        background-color: #f3f6f9;
        width: 44%;
        height: 120px;

        .feature-card-title {
          font-size: 24px;
          font-weight: bold;
          margin-bottom: 5px;
        }
        .feature-card-button {
          margin-top: 33px;
        }
      }
    }
  }
  .feature-card-right {
    background-color: #f1f5f8;
    margin-left: 10px;
    .feature-card-img {
      width: 648px;
      height: 160px;
    }
    .feature-card {
      padding: 20px 30px;
      background-color: #f3f6f9;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      height: 143px;
      width: 88%;
      .feature-card-title {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 5px;
      }
      .feature-card-button {
        margin-top: 33px;
        font-size: 16px;
        height: 46px;
        padding: 11px 23px;
        letter-spacing: 1px;
      }
    }
  }
}
</style>
