<template>
  <section class="container-fluid">
    <h1 id="main-headline" class="main-headline industry-title">{{ title }}</h1>
    <div class="product-teasers-container">
      <div class="jsx-engineering-tools-box engineering-tools-container"></div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { ref } from "vue";
// 根据路由参数去获取当前的title
let title = ref("过程自动化");
</script>

<style scoped lang="scss">
.industry-title {
  text-align: left;
}
</style>
