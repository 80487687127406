import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";

// 第三方插件
import ElementPlusConfig from "./config/element-plus-config";
import "element-plus/dist/index.css";
// css
import "./assets/css/global.scss";
import "./assets/css/font.css";
import "./assets/css/main.css";
const app = createApp(App);

// 全局注册Element Plus图标组件
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

// 使用Element Plus和Vue Router
app.use(ElementPlusConfig).use(router).mount("#app");
