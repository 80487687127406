<template>
  <div>
    <!-- Banner -->
    <!-- <HeroSection></HeroSection> -->
    <HeroSection></HeroSection>
    <!-- 产品一级分类 -->
    <ProductCategory></ProductCategory>
    <!-- 产品二级分类 -->
    <CarouselCategory></CarouselCategory>
    <!-- 一站式购物、经销商、样本库 -->
    <CallToAction></CallToAction>
    <!-- 产品特色1 -->
    <FeatureCard
      :industryList="featureCardList3"
      :solution="solution3"
    ></FeatureCard>
    <!-- 产品特色2 -->
    <FeatureCard
      :industryList="featureCardList2"
      :solution="solution2"
    ></FeatureCard>
    <!-- 产品特色3 -->
    <FeatureCard
      :industryList="featureCardList1"
      :solution="solution1"
    ></FeatureCard>
    <!-- 选型推荐 -->
    <DigitalEngineering
      :list="typeSelection"
      style="padding: 0 60px"
    ></DigitalEngineering>
  </div>
</template>

<script setup>
import HeroSection from "../../components/Home/HeroSection";
import CallToAction from "../../components/Home/CallToAction";
import ProductCategory from "../../components/Home/ProductCategory";
import CarouselCategory from "../../components/Home/CarouselCategory";
import FeatureCard from "../../components/Home/FeatureCard";
import DigitalEngineering from "../../components/Home/DigitalEngineering";
import { ref } from "vue";
let featureCardList1 = ref([
  {
    img: "https://www.festo.com.cn/media/cms/media/editorial/img/automation/Werkzeugmaschinen_Funktionsuebersicht_26571kc_1_fix444x250.jpg",
    title: "机床自动化",
    introduce: "实现机床的生产率最大化水平",
    textButton: "了解详情",
    alt: "Process Industry",
  },
  {
    img: "https://www.festo.com.cn/media/cms/media/editorial/img/automation/chemistry-industry_keyvisual_16913c_6_fix444x250.jpg",
    title: "化工自动化",
    introduce: "智能自动化解决方案",
    textButton: "了解过程",
    alt: "Seamless connectivity",
  },
]);

const solution1 = ref({
  img: "https://www.festo.com.cn/media/cms/media/editorial/img/Life_science_DNA_fix952x235.jpg",
  title: "生命科技",
  introduce: "智能医疗设备解决方案",
  textButton: "了解详情",
  alt: "Seamless connectivity",
});

let featureCardList2 = ref([
  {
    img: "https://www.festo.com/media/cms/media/editorial/img/svg_1/food-packaging-key-visual-16900c_31_fix444x250.jpg",
    title: " 食品加工和包装行业",
    introduce: "安全、效率、符合卫生级标准",
    textButton: "最佳搭配",
    alt: "Process Industry",
  },
  {
    img: "https://www.festo.com/media/cms/media/editorial/img/water-technology-16905c_16_fix444x250.jpg",
    title: "水处理行业",
    introduce: "高效洁净水处理工程",
    textButton: "水务管理",
    alt: "Seamless connectivity",
  },
]);

const solution2 = ref({
  img: "https://www.festo.com/media/cms/media/editorial/img/two-people-survey-axis_16947c_4_fix952x235.jpg",
  title: "最佳自动化解决方案",
  introduce: "达到最高生产率",
  textButton: "绝对适用",
  alt: "Seamless connectivity",
});

let featureCardList3 = ref([
  {
    img: "https://www.festo.com/media/cms/media/editorial/img/china/cc_content/hk/festo-auto-industry-intro_fix444x250_fix444x250.jpg",
    title: "汽车行业",
    introduce: "一站式汽车自动化解决方案",
    textButton: "了解详情",
    alt: "Process Industry",
  },
  {
    img: "https://www.festo.com/media/cms/media/editorial/img/pharmaceutical-industry-automation.jpg_16910c_7_fix444x250.jpg",
    title: "生物制药",
    introduce: "高效自动化系统",
    textButton: "高效生产",
    alt: "Seamless connectivity",
  },
]);

const solution3 = ref({
  img: "https://www.festo.com/media/cms/media/editorial/img/application-electronics-industry-16906c_1_fix952x235.jpg",
  title: "电子行业解决方案",
  introduce: "精准、可靠、具有成本效益",
  textButton: "了解详情",
  alt: "Seamless connectivity",
});

// Type selection
const typeSelection = ref([
  {
    id: 1,
    img: "https://www.festo.com/media/cms/media/editorial/img/person-blue-star-festo-core-product-range_fix696x391.jpg",
    title: "核心产品系列",
    introduce:
      "品质卓越，价格极具吸引力，能充分满足气压自动化和电动自动化领域中几乎所有的常见运用。品质卓越，价格极具吸引力，能充分满足气压自动化和电动自动化领域中几乎所有的常见运用。品质卓越，价格极具吸引力，能充分满足气压自动化和电动自动化领域中几乎所有的常见运用。品质卓越，价格极具吸引力，能充分满足气压自动化和电动自动化领域中几乎所有的常见运用。",
    textButton: "快速到货",
    alt: "Process Industry",
    path: "",
  },
  {
    id: 2,
    img: "https://www.festo.com/media/cms/media/editorial/img/precise-flexible-microdispenser_fix696x391.jpg",
    title: "化工自动化",
    introduce:
      "全球Ctrl Motor客户的实际应用案例：从小零件组装的小型自动化应用、到北极圈的大型阀门驱动器。",
    textButton: "各行各业",
    alt: "Seamless connectivity",
    path: "",
  },
  {
    id: 3,
    img: "https://www.festo.com/media/cms/media/editorial/img/cc/bionic-learning-network-00000-festo_fix696x391.jpg",
    title: "仿生学习网络",
    introduce:
      "为工厂和过程自动化提供新动力，也是每年德国汉诺威工业展的万众瞩目焦点。",
    textButton: "所有亮点",
    alt: "Seamless connectivity",
    path: "",
  },
]);
</script>
<style></style>
