<template>
  <div>
    <div
      v-for="(tag, index) in tags"
      :key="tag.id"
      @click="toggleSelection(index)"
      class="tag"
      :class="{ selected: selectedTagsIndices.includes(index) }"
    >
      {{ tag.name }}
    </div>
  </div>
</template>
<script setup>
import { ref, defineProps, defineEmits } from "vue";
const emit = defineEmits(["selectedTags"]);
let props = defineProps(["tags"]);
const selectedTagsIndices = ref([]);

const toggleSelection = (index) => {
  const position = selectedTagsIndices.value.indexOf(index);
  if (position > -1) {
    selectedTagsIndices.value.splice(position, 1);
  } else {
    selectedTagsIndices.value.push(index);
  }
  emit(
    "update:selectedTags",
    selectedTagsIndices.value.map((i) => props.tags[i])
  );
};
</script>
<style>
.tag {
  display: inline-block;
  cursor: pointer;
  padding: 1px 12px 2px;
  border: 1px solid #d9dce0;
  background: #d9dce0;
  color: #333;
  border-radius: 16px;
  text-align: center;
  margin-right: 10px;
  margin-bottom: 10px;
}

.selected {
  background: #fff;
  border: thin solid #33157a;
  color: #33157a;
}
</style>
