<template>
  <div class="call-to-action-box">
    <img :src="onlineFeature.img" alt="" style="width: 336px; height: 100%" />

    <div
      style="
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        background-color: #f3f6f9;
        height: 94%;
        width: 291px;
        padding: 30px 20px 0 25px;
      "
    >
      <p class="module-name">{{ onlineFeature.moduleName }}</p>
      <p class="sub-headline">{{ onlineFeature.title }}</p>
      <ul v-for="item in onlineFeature.feature" :key="item">
        <li>{{ item }}</li>
      </ul>
      <div style="padding-top: 140px">
        <el-button
          type="primary"
          style="width: 124px; height: 42px; font-size: 16px"
          >立即注册</el-button
        >
      </div>
    </div>
    <div class="other-module">
      <p>授权经销商</p>
    </div>
    <div class="other-dealer2">
      <p>产品样本</p>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
const onlineFeature = ref({
  id: 1,
  img: "https://www.festo.com.cn/media/cms/media/editorial/img/cc/BRAND-BB-News-Teaser-image_fix496x661.jpg",
  moduleName: "在线购物",
  title: "注册后享有：",
  feature: [
    "查看价格",
    "查看交货信息",
    "建立个人清单",
    "下载CAD图档",
    "追踪订单",
  ],
  textButton: "立即注册",
  alt: "Process Industry",
});
</script>

<style lang="scss" scoped>
.call-to-action-box {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 60px;
  margin: 70px 25px 100px 25px;
  height: 480px;
  .module-name {
    font-weight: 400;
  }
  .sub-headline {
    margin-top: 10px;
    font-size: 24px;
    margin-bottom: 15px;
  }
  li {
    list-style-type: disc;
    margin-left: 20px;
    margin-bottom: 7px;
  }

  .other-module {
    height: 100%;
    width: 336px;
    background: url("https://www.festo.com/media/cms/media/editorial/img/Festo-Official-Partner-16917c_1_fix496x661.jpg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    p {
      position: relative;
      right: 100px;
      top: 30px;
    }
  }

  .other-dealer2 {
    height: 100%;
    width: 336px;
    background: url("https://www.festo.com/media/cms/media/editorial/img/standards-product-approval-cpx-festo-22350c_2_fix496x661.jpg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    p {
      position: relative;
      right: 100px;
      top: 30px;
      font-size: 16px;
    }
  }
}
</style>
