<template>
  <div class="product-catelogue-box">
    <div
      style="
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;
      "
    >
      <h3 class="filter">筛选</h3>
      <span class="reset-search-btn">重置所有搜索条件</span>
    </div>
    <div>
      <div class="specific-directory">特定目录</div>
      <div class="core-product-range-box">
        <div class="core-product-range">核心产品范围</div>
      </div>
      <hr />
    </div>
    <div class="flex gap-2">
      <TagSelector :tags="tagsList" />
    </div>
  </div>
</template>

<script setup lang="ts">
import TagSelector from "../Common/TagSelector.vue";

import { ref } from "vue";
let tagsList = ref([
  { id: 1, name: "食品和包装" },
  { id: 2, name: "洁净室" },
  { id: 3, name: "ATEX 防爆区" },
  { id: 4, name: "采矿和冶金行业" },
  { id: 5, name: "过程自动化" },
  { id: 4, name: "电池生产" },
  { id: 5, name: "可持续发展" },
]);
</script>

<style scoped lang="scss">
.product-catelogue-box {
  background: inherit;
  // min-height: calc(100% + 315px);
  overflow-y: auto;
  position: static;
  font-size: 16px;
  text-align: left;
  .reset-search-btn {
    cursor: pointer;
    color: #33157a;
  }
  .core-product-range-box {
    display: inline-block;
    line-break: anywhere;
    max-width: 100%;
    padding-bottom: 8px;
    padding-right: 8px;
  }

  .filter {
    font-size: 24px;
  }

  .core-product-range {
    background-color: #d8dce1;
    border: thin solid #d8dce1;
    color: #000;
    //
    align-items: center;
    border-radius: 16px;
    cursor: pointer;
    display: flex;
    max-width: 100%;
    padding: 1px 12px 2px;
    text-align: center;
  }

  .specific-directory {
    margin-bottom: 12px;
    font-weight: 800;
  }

  hr {
    border: 0;
    border-top: 1px solid #d8dce1;
    margin-bottom: 14px;
    margin-top: 6px;
    display: block;
    height: 1px;
    padding: 0;
  }
}
</style>
