<template>
  <div class="product-industrial-box">
    <h2 class="h3" style="text-align: left; margin-bottom: 24px">
      选择您的 {{ title }}
    </h2>
    <div class="categories">
      <div v-for="item in list" :key="item.id" class="category">
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
          "
        >
          <img :src="item.img" alt="" style="width: 77px; height: 77px" />
          <div class="category-right-box">
            <div class="category-name">{{ item.name }}</div>
            <div class="category-introduce">{{ item.introduce }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
// 根据路由或者id显示标题
let title = ref("工厂自动化");
let list = ref([
  {
    id: 1,
    name: "驱动器",
    img: "https://www.festo.com/media/pim/717/D15000100116717_264x198.jpg",
    introduce: "气控、气伺服和机电无杆气缸和摆动气缸，用于工厂自动化",
  },
  {
    id: 2,
    name: "阀和阀岛",
    img: "https://www.festo.com/media/pim/255/D15000100116255_264x198.jpg",
    introduce:
      "标准阀和通用阀，专用型。手动和机械驱动阀；关断阀、流量控制阀、限压阀和",
  },
  {
    id: 3,
    name: "Motion Terminal（数字控制终端）",
    img: "https://www.festo.com/media/pim/027/D15000100149027_264x198.jpg",
    introduce: "流量 5501/min 电压 24VDC压力-0.9..8 bar。。。",
  },
  {
    id: 4,
    name: "传感器",
    img: "https://www.festo.com/media/pim/633/D15000100115633_264x198.jpg",
    introduce: "传感器和监控设备及其附件。",
  },
]);
</script>

<style scoped lang="scss">
.product-industrial-box {
  @media (min-width: 1440px) {
    .categories-container {
      max-width: 75%;
      min-width: 75%;
    }
  }

  @media (min-width: 768px) {
    .categories-container {
      margin: 0 auto;
      max-width: 66.6666%;
      min-width: 66.6666%;
    }
  }

  .categories {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    justify-content: flex-start;

    .category {
      padding: 24px 16px;
      background: #fff;
      border-radius: 4px;
      position: relative;
      transition: width 200ms ease-in-out;
      // height:315px
      width: 284px;
      height: 115px;

      .category-right-box {
        margin-left: 26px;
        width: calc(100% - 102px);
        display: flex;
        flex-direction: column;
      }

      .category-name {
        height: 38px;
        font-size: 14px;
        font-weight: bold;
        width: calc(100% - 34px);
        text-align: left;
        margin-bottom: 16px;
        margin-top: 8px;
      }

      .category-introduce {
        font-size: 14px;
        text-align: left;
        height: 54px;
        line-height: 18px;
        max-height: 54px;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        -webkit-line-clamp: 3;
      }
    }
  }
}

@media (min-width: 1440px) {
  .category {
    width: calc(33.33% - 16px);
  }
}

@media (min-width: 768px) {
  .category {
    margin: 0 24px 24px 0;
    width: calc(50% - 12px);
  }
}
</style>
