<template>
  <div class="products-category-box">
    <div class="product-category">
      <div
        v-for="(item, index) in categoryList"
        :key="item.id"
        :class="{ 'active-product-category': index == activeProductCategory }"
        class="product-category-name"
        @click="chooiceCategory(index)"
      >
        {{ item.categoryName }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
let activeProductCategory = ref(0);
let categoryList = ref([
  { id: 1, categoryName: "工業自動化" },
  { id: 2, categoryName: "過程自動化" },
  { id: 2, categoryName: "LifeTech Automation" },
]);
const chooiceCategory = (index) => {
  activeProductCategory.value = index;
};
</script>

<style lang="scss" scoped>
.products-category-box {
  background-color: #f2f3f5;
  width: 100%;

  .product-category {
    font-size: 16px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 50px;
    .product-category-name {
      margin: 0 20px;
      cursor: pointer;
      padding-bottom: 8px;
    }
  }

  .active-product-category {
    color: #3f8fd6;
    border-bottom: 4px solid #3f8fd6;
  }
}
</style>
