import { App } from "vue";
import {
  ElButton,
  ElHeader,
  ElMain,
  ElFooter,
  ElContainer,
  ElMenu,
  ElMenuItem,
  ElSubMenu,
  ElCarousel,
  ElCarouselItem,
  ElImage,
  ElCheckTag,
  ElTooltip,
  ElIcon,
  ElRow,
  ElCol,
  ElCollapse,
  ElCollapseItem,
  ElTable,
  ElTableColumn,
  // ElTabs,
  // ElTabPane,
} from "element-plus";

const components = [
  ElButton,
  ElHeader,
  ElMain,
  ElFooter,
  ElContainer,
  ElMenu,
  ElMenuItem,
  ElSubMenu,
  ElCarousel,
  ElCarouselItem,
  ElImage,
  ElCheckTag,
  ElTooltip,
  ElIcon,
  ElRow,
  ElCol,
  ElCollapse,
  ElCollapseItem,
  ElTable,
  ElTableColumn,
  // ElTabs,
  // ElTabPane,
];

export default {
  install(app: App): void {
    components.forEach((component) => {
      app.component(component.name, component);
    });
  },
};
