<template>
  <div class="carousel-container">
    <ArrowLeftBold
      style="width: 2em; height: 2em; margin-right: 8px; cursor: pointer"
      @click="prevPage"
    ></ArrowLeftBold>
    <div class="carousel-items">
      <div
        class="carousel-item"
        v-for="(item, index) in visibleItems"
        :key="index"
        @click="jump(item.path)"
      >
        <img
          :src="item.image"
          :alt="item.name"
          style="width: 96px; height: 129px"
        />
        <div>{{ item.name }}</div>
      </div>
    </div>
    <ArrowRightBold
      style="width: 2em; height: 2em; margin-left: 8px; cursor: pointer"
      @click="nextPage"
    ></ArrowRightBold>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useRouter } from "vue-router";

interface Category {
  name: string;
  image: string;
}

const router = useRouter();
const jump = (path: string) => {
  console.log(`output->path`, path);
  router.push(path);
};
const categories: Category[] = [
  {
    name: "马达和伺服驱动器1",
    image:
      "https://ctrl-motor.oss-cn-hongkong.aliyuncs.com/static/home/22621704940137_.pic_hd.jpg",
    path: "/products",
  },
  {
    name: "马达和伺服驱动器2",
    image:
      "https://ctrl-motor.oss-cn-hongkong.aliyuncs.com/static/home/22621704940137_.pic_hd.jpg",
    path: "/products",
  },
  {
    name: "马达和伺服驱动器3",
    image:
      "https://ctrl-motor.oss-cn-hongkong.aliyuncs.com/static/home/22621704940137_.pic_hd.jpg",
    path: "/products",
  },
  {
    name: "马达和伺服驱动器",
    image:
      "https://ctrl-motor.oss-cn-hongkong.aliyuncs.com/static/home/22621704940137_.pic_hd.jpg",
    path: "/products",
  },
  {
    name: "马达和伺服驱动器5",
    image:
      "https://ctrl-motor.oss-cn-hongkong.aliyuncs.com/static/home/22621704940137_.pic_hd.jpg",
    path: "/products",
  },
  {
    name: "马达和伺服驱动器",
    image:
      "https://ctrl-motor.oss-cn-hongkong.aliyuncs.com/static/home/22621704940137_.pic_hd.jpg",
    path: "/products",
  },
  {
    name: "马达和伺服驱动器7",
    image:
      "https://ctrl-motor.oss-cn-hongkong.aliyuncs.com/static/home/22621704940137_.pic_hd.jpg",
    path: "/products",
  },
  {
    name: "马达和伺服驱动器8",
    image:
      "https://ctrl-motor.oss-cn-hongkong.aliyuncs.com/static/home/22621704940137_.pic_hd.jpg",
    path: "/products",
  },
  {
    name: "马达和伺服驱动器9",
    image:
      "https://ctrl-motor.oss-cn-hongkong.aliyuncs.com/static/home/22621704940137_.pic_hd.jpg",
    path: "/products",
  },
  // 填充更多分类数据
];
const pageSize = 7;
const currentPage = ref(0);

const pageCount = Math.ceil(categories.length / pageSize);

const visibleItems = ref(categories.slice(0, pageSize));

function nextPage() {
  currentPage.value = (currentPage.value + 1) % pageCount;
  const start = currentPage.value * pageSize;
  const end = start + pageSize;
  visibleItems.value = categories.slice(start, end);
}

function prevPage() {
  currentPage.value = (currentPage.value - 1 + pageCount) % pageCount;
  const start = currentPage.value * pageSize;
  const end = start + pageSize;
  visibleItems.value = categories.slice(start, end);
}
</script>

<style scoped>
.carousel-container {
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 0 16px;
}
.carousel-items {
  display: flex;
  overflow: hidden;
  justify-content: space-between;
  width: 100%;
  font-size: 16px;
}
.carousel-item {
  margin: 0 25px;
}
</style>
